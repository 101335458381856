import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Project } from 'src/app/models/projects/project.model';
import { NewProject } from 'src/app/models/projects/new-project.model';
import { EditProject } from 'src/app/models/projects/edit-project.model';
import { ProjectsService } from 'src/app/services/projects.service';

import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { createProjectFormErrors } from 'src/app/config/error-messages';
import { alphaNumberRegex, alphaNumberNoEmojisRegex } from 'src/app/config/regex';
import moment from 'moment';
import { startDateValidation } from 'src/app/shared/custom-validators/date.validator';
import { dateBeforeMin } from 'src/app/shared/custom-validators/date-before-min.validator';
import { Response } from 'src/app/models/sign-up/generic-response.model';
import { Client } from 'src/app/models/databases/clients.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-manage-projects-form',
  templateUrl: './manage-projects-form.component.html',
  styleUrls: ['./manage-projects-form.component.scss']
})
export class ManageProjectsFormComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() project: Project;
  @Input() autofocus: boolean;

  private imgSrc: string = '';
  public imagePath: FileList;
  public message: string;
  public hasChanged: boolean;
  public showAlert: boolean
  public icon: string = '';
  public projectData: NewProject;
  public validForm: boolean = true;
  public minDate;
  public newStartDate: boolean = true;
  public monthInputError: boolean;
  public clientName: Client[] = [];

  // Form controls
  public projectControl: FormControl = new FormControl(' ', []);
  public clientNameControl: FormControl = new FormControl(null, []);
  public projectDescriptionControl: FormControl = new FormControl(null, []);
  public startDateControl: FormControl = new FormControl(null, []);
  public image: FormControl = new FormControl(null, []);
  public clientIdControl: FormControl = new FormControl(null, []);
  public isDefaultCheckboxControl : FormControl = new FormControl(false, []);

  // Validators
  public projectNameValidators: Validators[] = [
    Validators.required,
    patternValidator(alphaNumberRegex),
  ];
  public clientNameValidator: Validators[] = [
    Validators.required,
    patternValidator(alphaNumberRegex),
  ];
  public clientIdValidators: Validators[] = [
    Validators.required
  ];
  public projectDescriptionValidator: Validators[] = [
    Validators.required,
    patternValidator(alphaNumberNoEmojisRegex),
    Validators.minLength(10)
  ];
  public startDateValidator: Validators[] = [Validators.required,
    startDateValidation,
    dateBeforeMin(moment('1900', 'YYYY'))];
  
  // Errors
  public projectNameError = createProjectFormErrors.projectNameError;
  public clientError = createProjectFormErrors.clientError;
  public clientIdError = createProjectFormErrors.clientId;
  public projectDescriptionError =
    createProjectFormErrors.projectDescriptionError;
  public startDateError = createProjectFormErrors.startDateError;

  // Form group
  public formProject: FormGroup = new FormGroup({
    projectName: this.projectControl,
    clientId: this.clientIdControl,
    clientName: this.clientNameControl,
    projectDescription: this.projectDescriptionControl,
    startDate: this.startDateControl,
    projectImage: this.image,
    is_default: this.isDefaultCheckboxControl 
  });

  constructor(
    private projectService: ProjectsService,
    private router: Router
  ) {
    // setting the minimum date to 1900
    this.minDate = '1900-01-01';
    this.getProject();
  }

  ngOnInit() {
    if (this.project) {
      const startDateFormatted = moment.utc(this.project.startDate)
      .format('YYYY-MM-DD');
      this.newStartDate = false;
      this.projectData = {
        projectName: this.project.name,
        clientId: this.project.client[0].id,
        clientName: this.project.client[0].name,
        projectDescription: this.project.description,
        startDate: startDateFormatted,
        projectImage: this.project.image,
        is_default: this.project.isDefault
      }

      this.getProject();
      this.checkChangesInForm();
    } else {
      this.hasChanged = true;
    }
    this.getClients();
  }

  /**
   * getClients method
   * call to getClients from projectService to get all clients
   * from database
   * @returns {void}
   */
  private getClients(): void {
    this.projectService.getClients()
    .subscribe((response: Response) => {
      const data = response.data;
      if (data) {
        data.forEach(client => {
          this.clientName.push(client);
        });
      }
    });
  }

  /**
   * validateForm
   * Get all the form group data and checks if is valid data, to pass it to the
   * submitForm function in case you want to creat a project or to
   * edit project if you are editing data
   * @returns {void} void
   */
  public validateForm(): void {
    if (this.formProject) {
      this.message = '';
  
      let form: NewProject = {
        ...this.formProject.value,
        is_default: this.isDefaultCheckboxControl.value  
      };
  
      form.startDate = moment.utc(this.formProject.controls.startDate.value, ['MM/YYYY'])
        .format('YYYY-MM-DD');
  
      if (this.formProject.controls.startDate.invalid) {
        return;
      }
  
      if (this.formProject.controls.image && this.formProject.controls.image.valid) {
        this.formProject.controls.image.patchValue('');
      }
  
      if (this.imgSrc !== '') {
        form.projectImage = this.imgSrc;
      }
  
      if (this.project) {
        const editProject: EditProject = { 
          ...form,
          is_default: this.isDefaultCheckboxControl.value  
        };
        
        const fieldsToSend = {};
        for (const key in editProject) {
          if (editProject[key] !== null && editProject[key] !== undefined && editProject[key] !== '') {
            fieldsToSend[key] = editProject[key];
          }
        }
        this.editProject(fieldsToSend as EditProject);
      } else {
        const fieldsToSend = {};
        for (const key in form) {
          if (form[key] !== null && form[key] !== undefined && form[key] !== '') {
            fieldsToSend[key] = form[key];
          }
        }
        
        this.submitProject(fieldsToSend as NewProject);
      }
    } else {
      this.message = 'Form not valid';
    }
  }

  /**
   * submitProject
   * Sends all the form data to a service
   * @param {NewProject} form receives data from create project form
   * @returns {void} void
   *
   */
  private submitProject(form: NewProject): void {
    this.showAlert = false;
    const project = form;
    delete project.clientId;
    this.blockUI.start();
    this.projectService.createProject(project)
      .subscribe((newProject) => {
        this.goToDetailsView(newProject.data.id);
        this.icon = '';
        this.blockUI.stop();
      }, error => {
        this.showAlert = true;
        if (error.statusText === 'Unknown Error') {
          this.message = 'Error trying to send the data';
        } else {
          this.message = error.error.message
          this.getProject();
        }

        this.blockUI.stop();
      }
      );
  }

  /**
   * Sends the data to the edit project service
   * @param form the data from the form
   * @returns {void} void
   */
  private editProject(form: EditProject): void {
    this.showAlert = false;
    const project = form;
    this.blockUI.start();
    this.projectService.updateProject(project, this.project.id)
      .subscribe((success) => {
        this.goToDetailsView(this.project.id);
        this.blockUI.stop();
      }, (error) => {
        this.showAlert = true;
        if (error.statusText === 'Unknown Error') {
          this.message = 'Error trying to send the data';
        } else {
          this.message = error.error.message
        }

        this.blockUI.stop();
      });
  }

  /**
   * getProject
   * Gets project data from parent and set it to the form
   * @returns {void} void
   */
  private getProject(): void {
    if (this.project) {
      this.formProject.setValue(this.projectData);
      this.icon = this.project.image;
    }
  }

  /**
   * checkChangesInForm
   * go trough the values in the form and checks that they are different than the receives.
   * @returns {void} void
   */
  private checkChangesInForm(): void {
    // setting the date from the API and formating as the input needs
    this.startDateControl.setValue(this.projectData.startDate);
    this.projectData.startDate = moment(this.projectData.startDate).format('MM/YYYY');
    this.formProject.valueChanges.subscribe((data) => {
      //  checking if the properties are empty to set them to null
      // for difference checking
      if (data.projectImage === '') {
        data.projectImage = null;
      }

      for (const key in data) {
        if (data[key] === this.projectData[key]) {
          this.hasChanged = false;
        } else {
          this.hasChanged = true;
          return;
        }
      }
    })
  }

  /**
 * receiveImgBuffer
 * Receives the image buffer
 * @param {string} buffer
 * @returns {void} void
 */
  public receiveImgBuffer(buffer: string): void {
    this.imgSrc = buffer;
  }

  /**
   * checkEmptyValues
   * Validates if there are empty fields in the received data, and deletes them
   * @param {NewProject} data receives data from create project form
   * @returns {void}
   */
  public checkEmptyValues(data: NewProject): void {
    for (const key in data) {
      if (!data[key]) {
        delete data[key];
      }
    }
  }

  /**
   * goToDetailsView method
   * go back to the project details view. Emits a event that parent catch
   * @return {void}
   */
  public goToDetailsView(projectId: number): void {
    this.router.navigateByUrl(`manage/projects/${projectId}`);
  }

  /**
   * goToProjectsView Method
   * Go back to the projects view.
   * @returns {void}
   */
  public goToProjectsView(): void {
    this.router.navigateByUrl('manage/projects');
  }

    /**
   * goBack
   * emits an event whit a message to the parent
   * @returns {void} void
   */
  public goBack(): void {
    if (this.project) {
      this.goToDetailsView(this.project.id);
    } else {
      this.goToProjectsView();
    }
  }

  /**
   * mothErrorHandler
   * handles de error of the month input to push down the container.
   * @param {string} name name of the month input
   * @param {boolean} solved boolean that tells you if the error is solved
   */
  public mothErrorHandler(name: string, solved: boolean = false):void {
    if (name === 'startDate'&& !solved) {
      this.monthInputError = true;
    } else if (name === 'startDate'&& solved) {
      this.monthInputError = false;
    }
  }
}
