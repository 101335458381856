import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PermissionsResponse } from 'src/app/models/permissions-response.model';

import { ProjectRolesListResponse } from '../models/project-roles/project-roles-list-response.model';
import { ProjectRole } from '../models/projects/project-role.model';
@Injectable({
  providedIn: 'root',
})
export class ProjectRolesService {
  constructor(private http: HttpClient) {}

  /**
   * getPermissions method
   * @description gets the Permission from the backend
   * @returns {Observable<PermissionsResponse>} Observable<PermissionsResponse> - array containing all the necesary Permissions
   * for create Roles
   */
  public getPermissions(): Observable<PermissionsResponse> {
    return this.http
      .get<PermissionsResponse>(`${environment.apiUrl}/permissions`)
      .pipe(
        catchError((error) => {
          return of(error);
        }),
      );
  }

  /**
   * getProjectRoles method
   * @description Gets the proejct roles from the backend through pagination
   * @param {number} page array containing the roles to be sliced
   * @returns {Observable<ProjectRolesListResponse>} Observable<ProjectRolesListResponse> - array containing the page of roles
   */
  public getProjectRoles(): Observable<ProjectRolesListResponse> {
    const url: string = `${environment.apiUrl}/project-roles?active=true`;

    return this.http.get<ProjectRolesListResponse>(url).pipe(
      catchError((error) => {
        return of(error);
      }),
    );
  }

  /**
   * getProjectRoleById method
   * @description Gets a project role by id
   * @param {projectRoleId} projectRoleId element to be fetched
   * @returns {Observable<ProjectRole>} Observable<ProjectRole> - Object containing the project role
   */
  public getProjectRoleById(projectRoleId: number): Observable<ProjectRole> {
    const url = `${environment.apiUrl}/project-roles/${projectRoleId}`;
    return this.http.get<any>(url).pipe(
      map((response) => {
        return {
          id: response.data.id,
          isApproved: response.data.isApproved,
          name: response.data.name,
        };
      }),
    );
  }

  /**
   * toggleProjectRoleApproval method
   * @description  Updates the project role approval status
   * @param {projectRole} projectRole element to be updated
   * @returns {Observable<CreateProjectRoleResponse>} Observable<CreateProjectRoleResponse> - Object containing the updated project role
   */
  public toggleProjectRoleApproval(
    projectRole: ProjectRole,
  ): Observable<ProjectRole> {
    const url = `${environment.apiUrl}/project-roles/${projectRole.id}`;
    return this.http
      .put<any>(url, {
        isApproved: projectRole.isApproved,
        name: projectRole.name,
      })
      .pipe(
        map((response) => {
          return {
            id: projectRole.id,
            isApproved: response.data.isApproved,
            name: response.data.name,
          };
        }),
      );
  }
  /**
   * createProjectRole method
   * @description Creates a new project role
   * @param {projectRole} projectRole element to be created
   * @returns {Observable<CreateProjectRoleResponse>} Observable<CreateProjectRoleResponse> - Object containing the new project role
   */
  public createProjectRole(projectRole: ProjectRole): Observable<ProjectRole> {
    const url = `${environment.apiUrl}/project-roles`;
    return this.http
      .post<any>(url, {
        name: projectRole.name,
      })
      .pipe(
        map((response) => {
          return {
            id: projectRole.id,
            isApproved: response.data.isApproved,
            name: response.data.name,
          };
        }),
      );
  }

  /**
   * DeleteProjectRole method
   * @description Delete a project role by id
   * @param {projectRoleId} projectRoleId element to be deleted
   * @returns {Observable<CreateProjectRoleResponse>} Observable<CreateProjectRoleResponse> - Object containing the new project role
   */
  public deleteProjectRole(projectRoleId: number): Observable<ProjectRole> {
    const url = `${environment.apiUrl}/project-roles/${projectRoleId}`;
    return this.http.delete<any>(url).pipe(
      map((response) => {
        return {
          id: projectRoleId,
          isApproved: response.data.isApproved,
          name: response.data.name,
        };
      }),
    );
  }

  /**
   * updateProjectRole method
   * @description Updates a project role by id
   * @param {projectRole} projectRole element to be updated
   * @returns {Observable<CreateProjectRoleResponse>} Observable<CreateProjectRoleResponse> - Object containing the updated project role
   */
  public updateProjectRole(projectRole: ProjectRole): Observable<ProjectRole> {
    const url = `${environment.apiUrl}/project-roles/${projectRole.id}`;
    return this.http
      .put<any>(url, {
        name: projectRole.name,
      })
      .pipe(
        map((response) => {
          return {
            id: projectRole.id,
            isApproved: response.data.isApproved,
            name: response.data.name,
          };
        }),
      );
    }
}
