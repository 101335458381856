/**
 * Exports object with the error messages for each of the
 * different inputs in forms
 */

 /**
  * Dashboard
  * Error messages to be present in Dashboard forms
  */
// Experiences
const addExperienceErrors = {
  userId: {
    required: 'User id is required',
    pattern: 'User id must be a number',
  },
  position: {
    required: 'Position is required',
    pattern: 'Position is invalid',
  },
  company: {
    required: 'Company is required',
    pattern: 'Company is invalid',
  },
  location: {
    required: 'Location is required',
    pattern: 'Location is invalid',
  },
  fromDate: {
    required: 'Date required',
    dateAfterNow: 'Invalid date',
    invalidDate: 'Invalid date',
    dateBeforeMin: 'Invalid date',
  },
  toDate: {
    required: 'Date required',
    dateAfterNow: 'Invalid date',
    invalidDate: 'Invalid date',
    dateBeforeMin: 'Invalid date',
  },
  responsibilities: {
    validArray: 'No repeated values allowed',
  },
  responsibility: {
    required: 'At least one responsibility is required',
    pattern: 'Responsibility is invalid',
    notUnique: 'No repeated values allowed',
  },
};

// My Projects
const myProjectsErrors = {
  status: {
    required: 'Status is required',
  },
  involved: {
    required: 'User involvement is required',
  },
};

/**
 * Manage
 * Error messages to be present in Manage forms
 */
// Employees
const assignRoleErrors = {
  role: {
    required: 'Role is required',
  },
};

// Projects
const addTeamMemberErrors = {
  projectId: {
    required: 'Project Id is required',
  },
  userId: {
    required: 'User is not valid',
  },
  user: {
    required: 'User is required',
  },
  weeklyHours: {
    min: 'Hours can\'t be less than 1',
    max: 'Hours can\'t be more than 40',
    required: 'Hours are required',
    pattern: 'Hours is not a valid number',
  },
  variability: {
    required: 'Hours variability is required',
  },
  role: {
    required: 'Role is required',
  },
};

const addClientTeamMemberErrors = {
  clientTeamMemberName: {
    required: 'Member name is required',
    patternValidator: 'Member name is not valid',
  },
  email: {
    pattern: 'Email is not valid',
    EmailRepeat: 'The email is already registered',
  },
  role: {
    required: 'Role is required',
    patternValidator: 'Role is not valid',
  },
};

// Reports
const workingHoursReportErrors = {
  fromDate: {
    required: 'From date is required',
    invalidDate: 'Invalid date',
  },
  toDate: {
    required: 'To date is required',
    invalidDate: 'Invalid date',
  },
  filterUser: {
    required: 'User not found',
  },
}

// exports object with all the error messages for the create project form inputs.
 const createProjectFormErrors = {
  projectNameError: {
    required: 'Project name is required',
    patternValidator: 'Project name is not valid'
  },
  clientError: {
    required: 'Client is required',
    patternValidator: 'Client name is not valid'
  },
  clientId: {
    required: 'Client is not valid',
    patternValidator: 'Client name is not valid'
  },
  clientEmailError: {
    patternValidator: 'Email is not valid',
  },
  projectDescriptionError: {
    required: 'Description is required',
    patternValidator: 'Description text is not valid',
    minlength: 'Description must have more than 10 characters'
  },
  startDateError: {
    required: 'Date is required',
    invalidDate: 'Invalid date',
    dateBeforeMin: 'Invalid date',
  }
};

//messages for all inputs of signup flow
const signUpFormErrorMessages = {
  firstNameError: {
    required: 'First name is required',
    pattern: 'First name is not valid',
  },
  lastNameError: {
    required: 'Last name is required',
    pattern: 'Last name is not valid',
  },
  emailErrors: {
    required: 'Email is required',
    pattern: 'Email is not valid',
    emailRepeat: 'You have an account already registered',
    emailNotFound: 'Email does not exist as an active BTS account'
  },
  passwordErrors: {
    required: 'Password is required',
    pattern: '8 digits, at least 1 lower case, 1 upper case, 1 number, and one of these signs: @.-_!?',
  },
  validatePasswordErrors: {
    required: 'Password confirmation is required',
    pattern: 'Password confirmation is not valid',
  },
  fieldErrors: {
    required: 'Field is required',
  },
  seniorityErrors: {
    required: 'Seniority is required',
  },
  positionErrors: {
    required: 'Position is required',
    pattern: 'Position is not valid',
  },
  locationErrors: {
    required: 'Location is required',
  },
  descriptionError: {
    required: 'Description is required',
    pattern: 'Description is not valid',
  },
};

const setNewPasswordErrors = {
  passwordErrors: {
    required: 'Password is required',
    pattern: '8 digits, at least 1 minus, 1 mayus, 1 number, and one of these signs: @.-_!?',
  },
  confirmPasswordErrors: {
    required: 'Confirm password is required',
  },
};

const loginErrors = {
  emailErrors: {
    required: 'Email is required',
    pattern: 'Email is not valid',
  },
  passwordErrors: {
    required: 'Password is required'
  },
}

const forgotPasswordErrors = {
  emailErrors:{
    required: 'Email is required',
    pattern: 'Email is not valid',
  },
}

const skillErrors = {
  skillFieldErrors: {
    required: 'Skill is required',
    patternValidator: 'Invalid skill',
    maxlength: 'No more than 35 characters',
  }
}
//messages for all inputs of signup flow
const userdescriptionErrors = {
  descriptionError: {
    required: 'Description is required',
    patternValidator: 'Description is not valid',
    minlength: 'Description cannot be less than ten characters',
  },
};

//messages from all inputs of timesheet component
const timesheetErrors = {
  dateError: {
    required: 'Date is required'
  },
  projectError: {
    required: 'Project name is required'
  },
  dedicatedHoursError: {
    min: '1 hour min',
    max: '24 hours max',
    required: 'Hours required',
    pattern: 'Is not a valid hour',
  },
  taskError: {
    required: 'Description is required',
    pattern: 'Task is not valid'
  },
  notesError: {
    pattern: 'Note is not valid',
    required: 'Note is required'
  }
}

const createRolesErrors = {
  createRole: {
    required: 'Role name is required',
    patternValidator: 'Invalid role name'
  }
}

const createProjectRoleErrors = {
    createProjectRole: {
      required: 'Project Role name is required',
      patternValidator: 'Invalid Project role name'
    }
  }

// Timesheet Selectors
const timesheetSelectorsErrors = {
  status: {
    required: 'Status is required',
  },
  period: {
    required: 'Period is required',
  },
};

// Update Check In errors
const updateCheckInErrors = {
  timeError: {
    required: 'New time is required',
    pattern: 'Time not valid',
  },
};

const createRegionErrors = {
  regionNameError: {
    required: 'Region is required',
    patternValidator: 'Region is not valid',
    notUnique: 'Do not repeat values',
  },
  locationNameError: {
    required: 'Location is required',
    patternValidator: 'Location is not valid',
    notUnique: 'Do not repeat values',
  }
}

const databasesAreasErrors = {
  areasErrors: {
    required: 'Area is required',
    patternValidator: 'Area is not valid',
    notUnique: 'Do not repeat values',
  }
}

const databasesSenioritiesErrors = {
  senioritiesErrors: {
    required: 'Seniority is required',
    patternValidator: 'Seniority is not valid',
    notUnique: 'Do not repeat values',
  }
}

const databasesClientsErrors = {
  clientsErrors: {
    required: 'Client is required',
    patternValidator: 'Client is not valid',
    notUnique: 'Do not repeat values',
  }
}

const rolesResponseErrors = {
  invalidName: 'Role name is not valid.',
  repeatedName: 'Role name already exists.',
  roleNoExists: 'Role no exists.',
  default: 'Something went wrong',
}

export {
  assignRoleErrors,
  myProjectsErrors,
  addExperienceErrors,
  addTeamMemberErrors,
  createProjectFormErrors,
  signUpFormErrorMessages,
  addClientTeamMemberErrors,
  workingHoursReportErrors,
  setNewPasswordErrors,
  loginErrors,
  forgotPasswordErrors,
  skillErrors,
  userdescriptionErrors,
  timesheetErrors,
  createRolesErrors,
  timesheetSelectorsErrors,
  updateCheckInErrors,
  createRegionErrors,
  databasesAreasErrors,
  databasesSenioritiesErrors,
  databasesClientsErrors,
  rolesResponseErrors,
  createProjectRoleErrors
};
