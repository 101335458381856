import { 
  Component, 
  OnInit,  
  OnChanges, 
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild
} from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { Project } from 'src/app/models/projects/project.model';
import { UsersUnassigned } from 'src/app/models/users-unassigned.model';
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { TeamMemberService } from 'src/app/services/team-member.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { JoinPipe } from 'src/app/pipes/join.pipe';

import { addTeamMemberErrors } from 'src/app/config/error-messages';
import { ToastService } from 'src/app/services/toast.service';
import { SelectorComponent } from 'src/app/shared/components/selector/selector.component';

@Component({
  selector: 'app-add-team-member',
  templateUrl: './add-team-member.component.html',
  styleUrls: ['./add-team-member.component.scss'],
  providers: [JoinPipe],
})
export class AddTeamMemberComponent implements OnInit, OnChanges {
  
  public projectId: number;
  public project: Project;

  public users: UsersUnassigned[];
  public variabilities: SelectorOption[] = [];
  public roles: SelectorOption[] = [];
  public displayWeeklyErrors: boolean = false;
  
  // form controls
  public projectIdControl: FormControl = new FormControl(
    '', 
    [Validators.required]
  );
  public userIdControl: FormControl = new FormControl('', []);
  public userControl: FormControl = new FormControl('', []);
  public weeklyHoursControl: FormControl = new FormControl('', []);
  public variabilityControl: FormControl = new FormControl('Fixed', []);
  public roleControl: FormControl = new FormControl('', []);
  public leaderControl: FormControl = new FormControl(true, []);
  public activeControl: FormControl = new FormControl(true, []);
  @ViewChild('roleSelector', { static: false }) roleSelector: SelectorComponent;

  // validators
  public userIdValidators: Validators[] = [Validators.required];
  public userValidators: Validators[] = [Validators.required];
  public weeklyHoursValidators: ValidatorFn[] = [
    Validators.min(1), 
    Validators.max(40),
    Validators.required,
    Validators.pattern(/^[0-9]*$/),
  ];
  public variabilityValidators: Validators[] = [Validators.required];
  public roleValidators: Validators[] = [Validators.required];

  // form group
  public addTeamMemberForm: FormGroup = new FormGroup({
    projectID: this.projectIdControl,
    userID: this.userIdControl,
    user: this.userControl,
    weeklyHours: this.weeklyHoursControl,
    variability: this.variabilityControl,
    roleID: this.roleControl,
    leader: this.leaderControl,
    active: this.activeControl,
  });

  // errors
  public projectIdErrors = addTeamMemberErrors.projectId;
  public userIdErrors = addTeamMemberErrors.userId;
  public userErrors = addTeamMemberErrors.user;
  public weeklyHoursErrors = addTeamMemberErrors.weeklyHours;
  public variabilityErrors = addTeamMemberErrors.variability;
  public roleErrors = addTeamMemberErrors.role;

  constructor(
    public join: JoinPipe, 
    private teamMemberService: TeamMemberService,
    private projectsService: ProjectsService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private router: Router
  ) {
    this.activatedRoute.parent.parent.params.subscribe((params) => {
      this.projectId = params.id;
      this.getProject(this.projectId);
    });
  }

  ngOnInit() {
    this.variabilities = [
      {
        id: null,
        name: 'Fixed',
      },
      {
        id: null,
        name: 'Variable',
      },
    ];
    this.getProject(this.projectId);
  }

  /**
   * validateNumberInput method
   * validate tha the hours per week input admit only
   * numeric charaters
   * @returns {void}
   */
  public validateNumberInput(): void {
    const value = this.addTeamMemberForm.get('weeklyHours').value;

    if (!value.match("^[0-9]*$")) {
      this.addTeamMemberForm.get('weeklyHours').setValue(value.slice(0, value.length - 1)); 
    }
  }

  /**
   * getProject Method
   * This method subscribes to a getProject method in Projects Service
   * and retrieves the data of an existing project
   * @param {number} id - Project ID of the project to retrives the data
   * @returns {void}
   */
  public getProject(id: number): void {
    this.projectsService.getProject(id)
      .subscribe((project) => {
        this.project = project.data;
        this.setUpData();
      });
  }

  /**
   * checkWeeklyHoursErrors method
   * set displayWeeklyErrors flag to true if there are errors
   * @returns {void}
   */
  public checkWeeklyHoursErrors(): void {
    if (this.weeklyHoursControl.errors) {
      this.displayWeeklyErrors = true;
    } else {
      this.displayWeeklyErrors = false;
    }
  }

  /**
   * ngOnChanges hook
   * sets the project id once available
   * @param {SimpleChanges} changes object containing the changes of the
   * input properties
   * @returns {void} 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['project'] && changes['project'].currentValue) {
      this.projectIdControl.setValue(this.project.id);
    }
}

  /**
   * getProjectRoles method 
   * method that uses the project role service to obtain the project roles
   * availables to the project
   * @returns {void}
   */
  public getProjectRoles(): void {
    this.teamMemberService.getProjectRoles().subscribe(result => {
      this.roles = result.data;
      this.getUsersUnassigned();
    });
  }

  /**
   * getUsersUnassigned method
   * this method get all the users that have not yet assigned
   * to a especific project
   * @returns {void}
   */
  public getUsersUnassigned(): void {
    this.teamMemberService.getUsersUnassigned(this.projectIdControl.value).subscribe(result => {
      this.users = result.data;
    });
  }

  /**
   * resetForm method
   * Resets the form to its initial state while preserving project-related data
   * @returns {void}
   */
  public resetForm(): void {
    const projectId = this.projectIdControl.value;
    
    // Temporarily remove validators from role control
    this.roleControl.clearValidators();
    this.roleControl.updateValueAndValidity();
    
    // Clear the form
    this.addTeamMemberForm.reset();
    
    // Reset individual controls with their values
    this.addTeamMemberForm.patchValue({
      projectID: projectId,
      variability: 'Fixed',
      leader: false,
      active: true,
      roleID: null
    });
  
    // Reset individual controls
    this.userIdControl.reset();
    this.userControl.reset();
    this.weeklyHoursControl.reset();
    
    // Clear role selector
    if (this.roleSelector) {
      this.roleSelector.clear();
    }
  
    // Reset error displays
    this.displayWeeklyErrors = false;
    
    // Get fresh data
    this.getUsersUnassigned();
    
    // Add back validators after a short delay
    setTimeout(() => {
      this.roleControl.setValidators([Validators.required]);
      this.roleControl.updateValueAndValidity();
      this.roleControl.markAsUntouched();
      this.cd.detectChanges();
    }, 100);
  }
  /**
   * onSubmit method
   * handle the logic when submitting the form
   * @returns {void}
   */
  public onSubmit(): void {
    const formData = { ...this.addTeamMemberForm.value };
    delete formData.user;
    formData.weeklyHours = parseInt(formData.weeklyHours);

    if (formData.active === true) {
      delete formData.active;
    } else {
      formData.active = new Date().toISOString().slice(0, 10);
    }

    this.teamMemberService.addTeamMemberProject(formData).subscribe({
      next: (result) => {
        if (result.status === 'success') {
          this.toastService.show('Team member added successfully', 'success');
          this.goToEditView();
        }
      },
      error: (error) => {
        this.toastService.show('Error adding team member', 'error');
      }
    });
  }

  /**
   * onSubmitAndAddAnother method
   * Handles the logic when submitting the form and wanting to add another member
   * @returns {void}
   */
  public onSubmitAndAddAnother(): void {
    if (this.addTeamMemberForm.valid) {
      const formData = { ...this.addTeamMemberForm.value };
      delete formData.user;
      formData.weeklyHours = parseInt(formData.weeklyHours);

      if (formData.active === true) {
        delete formData.active;
      } else {
        formData.active = new Date().toISOString().slice(0, 10);
      }

      this.teamMemberService.addTeamMemberProject(formData).subscribe({
        next: (result) => {
          if (result.status === 'success') {
            this.toastService.show('Team member added successfully', 'success');
            this.resetForm();
          }
        },
        error: (error) => {
          this.toastService.show('Error adding team member', 'error');
        }
      });
    }
  }

  /**
   * preventPasting method
   * prevents the pasting of values in certain inputs
   * @param {ClipboardEvent} event event triggered
   * @return {void}
   */
  public preventPasting(event: ClipboardEvent): void {
    const copiedData = event.clipboardData.getData('Text');

    // do not allow anything other than numbers
    if (isNaN(+copiedData)) {
      event.preventDefault();
    }
  }

  /**
   * setUpData Method
   * Set Up the necessary data to render the component
   * @returns {void}
   */
  public setUpData(): void {
    this.getProjectRoles();
    this.projectIdControl.setValue(this.project.id);
    this.weeklyHoursControl.setValidators(this.weeklyHoursValidators);
    this.weeklyHoursControl.updateValueAndValidity();
    this.projectIdControl.setValue(this.project.id);
  }

  /**
   * goToEditView Method
   * Redirects you to the Edit Project View
   * @returns {void}
   */
  public goToEditView(): void {
    this.router.navigateByUrl(`manage/projects/${this.projectId}/edit`);
  }
}